#rideguide-archive-wrapper,
#faq-archive-wrapper {
  h2 {
    padding-bottom: 0 !important;
  }

  .hero-row .card {
    padding: 0;
    height: 60vh;
    overflow: hidden;

    img {
      height: 60vh !important;
      width: 100%;
    }

    .card-img-overlay {
      padding-left: 3rem;
      padding-bottom: 3rem;

      .rideguide-breadcrumb {
        margin-top: auto;
        color: $theme-white;
      }
    }
  }

  // media above 2150px
  @media (min-width: 2150px) {
    .rideguide-archive-content {
      min-height: 75vh !important;

      .rideguide-archive-gallery {
        right: 6rem !important;
      }

      .rideguide-archive-description {
        padding: 5rem !important;
      }
    }
  }

  // media above 1500px
  @include media-breakpoint-up(xxl) {
    .rideguide-archive-content {
      min-height: 65vh !important;

      .rideguide-archive-gallery {
        //width: 850px !important;
        right: 6rem !important;
      }

      .rideguide-archive-description {
        padding: 5rem !important;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .rideguide-archive-content {
      .rideguide-archive-gallery {
        position: absolute !important;
        top: 40vh;
        width: 50vw !important;
        max-width: 850px !important;
        right: 2rem;
      }

      .rideguide-archive-description {
        padding: 2rem !important;
      }
    }
  }

  // normal
  .rideguide-archive-content {
    padding: 3rem;
    min-height: 50vh;

    .rideguide-archive-gallery {
      position: inherit;
      width: 100%;
    }

    .rideguide-archive-description {
      padding-bottom: 2rem;

      p {
        padding-bottom: 2rem;
      }
    }

    .rideguide-cat-image img {
      width: 100%;
    }


    .rideguide-cat-gallery {
      padding-left: 0.75rem;
      padding-right: 0.75rem;

      .rideguide-cat-gallery-item {
        padding: 0;
      }
    }
  }
}

