.woocommerce {
  // Product page  // nav tabs
  .product {
    margin-top: 3rem;
    
    .wc-tabs-wrapper .nav-link {
      text-decoration: none;
    }

    .wc-tabs-wrapper .nav-pills {
      a {
        border: 0.5px solid $theme-dark;
        border-radius: 0.25rem;
        margin: 0.25rem;
      }

      .active {
        a {
          color: $theme-white !important;
          background-color: $theme-dark !important;
          border: none;
        }
      }
    }

    // Variation form-control & qty input
    .variations .form-group {
      label {
        font-weight: 500 !important;
      }
    }

    input.form-control.qty {
      padding: 0;

      ::-webkit-inner-spin-button {
        margin-top: -6px;
        margin-bottom: -8px;
      }
    }

    /*--------------------
    * product summary - single product template
    -----------------*/

    .product-summary {
      .woocommerce-variation-add-to-cart,
      .ar-add-to-cart .cart {
        margin-top: 0.5rem;
      }

      .form-control-plaintext {
        padding: 0;
        p {
          margin: 0;
        }
      }

      .ar-product-price {
      }

      span.price {
        font-weight: 300;
      }

      //financeit

      .financeit {
        h4 {
          font-size: 0.9rem !important;
          margin: 0;
        }
        img {
          margin-left: -1rem;
          margin-top: -0.5rem;
        }
      }

      .trybefore {
        text-align: left;

        p {
          font-size: 0.875rem !important;
          line-height: 1.5 !important;
        }
      }
    }

    .shop_attributes {
      th {
        font-weight: 500;
      }
      td {
        font-style: normal;
      }
      p {
        font-size: 1rem;
      }
    }

    // Image gallery

    .product-images {
      .flex-control-thumbs {
        margin-top: 0.25rem !important;
        li {
          margin: 0.25rem !important;
          width: 20% !important;
        }
      }
      .woocommerce-product-gallery {
        width: 80% !important;
        margin-left: 10% !important;
      }
    }
  } //.product
} //WooCommerce
