// small snippet
.snippet-small-holder {
  .snippet-small-list {
    list-style: none;
    padding-left: 0;
    
    .snippet-logo-col {
      align-self: center;
      img {
        opacity: 0.75;
      }
    }
    
    .snippet-small {
      border: none;
      background: $gray-100;
      margin-top: 0 !important;
      font-family: sans-serif !important;
      font-size: 0.8rem !important;
      line-height: 1rem !important;
      margin-bottom: 1rem !important;

      
      .snippet-title {
        padding-bottom: 0.5rem;
        font-weight: 600;
      }

      i {
        padding-left: 0.5rem;
        color: $gray-800;
        opacity: 0.75;
      }

      img {
        float: left;
        margin-right: 1rem;
      }
      
    }
  }
}

// reserve snippet types
.snippet-tiles {
  img {
    min-height: 64px;
  }

  .snippet-tile {
    padding-top: 1.5rem;
  }

  .card {
    background: $gray-100;
    border: none;
    height: 100%;

    .card-title {
      text-align: center;
      padding: 1rem;
      h2 {
        font-size: 1.5rem !important;
        font-weight: 500 !important;
        padding: 0 !important;
      }
    }

    .card-text {
      li,
      p {
        font-size: 1rem !important;
        line-height: 1.5rem !important;
        text-align: left;
      }
      small {
        font-weight: 300;
        opacity: 0.6;
      }
    }
  }
}

// snippet full-width section
.snippet {
  .snippet-card {
    //padding: 1rem;
    //padding-right: 2rem;

    ul {
      list-style: none;
      padding-left: 0;
    }

    li,
    p {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }

    h3 {
      font-size: 1.2rem;
    }

    .card-title {
      font-size: 1.75rem;
    }

    .card-title,
    h2,
    h3 {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  @media (min-width: 1200px) {
    // 769px - 1024px && 1025px - 1200px
    .snippet-card {
      padding: 3rem !important;
    }
  } // . max-width 1200px

  @media (min-width: 768px) {
    .snippet-card {
      padding: 1rem;
    }
  }

  @media (max-width: 767px) {
    // 481px - 768px
    .snippet-card {
      padding: 0.25rem;

      .card-title {
        font-size: 1.5rem;
      }

      h3 {
        font-size: 1rem;
      }

      li {
        font-size: 0.9rem;
      }
    }
  } // . max-width 768px

  @media (max-width: 480px) {
    .snippet-card {
      padding: 1rem;
    }
  } // . max-width 480px
} // .snippet

.snippet-fullwidth {
  background-color: $feature-light;
  padding-left: 0;
  padding-right: 0;
  overflow: clip;
  img {
    //  position: absolute;
  }
}
