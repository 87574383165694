
// Single product type template
#single-reserve-wrapper {
  .reserve-header-content {
    padding: 3rem;
  }

  .reserve-breadcrumb {
    font-size: 0.92em;
  }
  .entry-title {
    padding: 0 !important;
    margin-bottom: 1.5rem;
  }

  .pricing-callout {
    font-size: 2rem;
    font-weight: 500;
    span {
      font-size: 1.1rem;
      font-weight: 300;
    }
  }

  #reserve-book {
    position: relative;
    z-index: 10;
    margin-top: 3rem;
    margin-bottom: -16rem;
  }

  .reserve-images {
    .wp-post-image {
      padding: 1rem 0.5rem;
    }
  }
  .reserve-gallery {
    list-style: none;
    display: inline-flex;
    overflow: clip;
    padding: 0;

    .reserve-gallery-item {
      padding: 0.5rem;
    }
  }
} // .single-reserve

#reserve-related {
  .card-deck {
    padding-top: 2rem;
  }
  .card {
    filter: drop-shadow(0 3px 3px #cdcdcd);

    .card-text {
      font-size: 1rem;
    }

    .card-footer {
      border-top: none;
      font-style: italic;
      a {
        color: $gray-800;
      }
    }
  }
}
