/*---------
Routes Navbars
-------*/

// Start homepage color change.
.home,
.tax-reserve_category,
.reserve-category-page,
.page-template-category-reserve,
.page-template-rideguide-page,
.page-template-faq-page {
  #routes-nav {
    margin-bottom: -80px;

    .navbar-toggler,
    .nav-link,
    svg.ar-logo,
    svg.whistler-logo {
      color: $theme-white;
      fill: $theme-white;
      filter: brightness(100);
    }

    .wc-block-mini-cart {
      .wc-block-mini-cart__button {
        color: $theme-white !important;
      }
    }
  }
}

// End homepage color change

#routes-nav {
  z-index: 999;
  height: 80px;

  .wc-block-mini-cart {
    .wc-block-mini-cart__button {
      color: $theme-dark !important;
    }
  }


  .btn-close {
    z-index: 2000;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-toggle:hover {
    text-decoration: underline;
  }

  a {
    font-weight: 200;
    cursor: pointer;
  }

  h2 {
    font-size: 1.75rem;
    font-weight: 600;
  }

  h3 {
    font-size: 1.1rem;
    line-height: 2rem;
    padding: 0;
    font-weight: 500;

    .nav-arrow {
      font-weight: 200;
      font-size: 1rem;
      padding-left: 0.25rem;
      position: absolute;
      bottom: 0.5rem;
      display: none;
    }
    :hover {
      padding-left: 0.5rem;
    }
  }

  h3.show {
    .nav-arrow {
      display: inline !important;
    }
  }

  li,
  ul {
    list-style: none !important;
    padding-left: 0;
  }

  .nav-link {
    font-size: 1.25rem;
    font-weight: 500;
  }

  .ar-logo {
    height: 60px;
    .ar-logo-text {
      display: none;
    }
  }

  .navbar-brand {
    width: 50px;
    margin-right: 0 !important;
  }

  @include media-breakpoint-up(md) {
    width: auto;
    margin-right: inherit;
  }

  @include media-breakpoint-up(lg) {
    .ar-logo .ar-logo-text {
      display: block;
    }
  }

  .offcanvas {
    background-color: $theme-white;
    padding: 1.5rem;
    //1.3.12 bug fix - header menu not showing. Possibly understrap update conflict.
    visibility: inherit !important;

    .search-form {
      padding: 0rem 2rem 2rem 2rem;
      max-width: 400px;
    }

    @include media-breakpoint-up(md) {
      .offcanvas-dropdown {
        overflow: scroll;
        top: 0 !important;
        left: 400px !important; // match to .off-canvas-header width below.
        padding-top: 150px;

        li {
          line-height: 2rem !important;
        }
      }
    }

    @include media-breakpoint-down(md) {
      width: 100%;

      .offcanvas-dropdown {
        background-image: none !important;
        background-color: $theme-white !important;

        a,
        h2,
        h3,
        h4,
        p {
          color: $theme-dark !important;
        }

        a {
          font-size: 1rem !important;
          line-height: 2rem !important;
        }

        h4 {
          font-size: 1.1rem !important;
          margin-top: 1rem !important;
        }

        overflow: auto !important;
        padding-bottom: 185px !important;
      }
    }

    .offcanvas-dropdown {
      position: fixed;
      margin-left: 0;
      top: 185px;
      left: 0;
      height: 100%;
      width: 100%;
      padding-left: 2.5rem;
      border: none !important;
      border-radius: 0;
      background-color: $theme-dark;
      background-size: cover;
      background-clip: border-box;
      background-position: center;


      li {
        line-height: 1rem;
      }

      .back {
        a,
        .nav-arrow {
          font-size: 0.8rem !important;
        }
      }

      a {
        color: $theme-white;
        font-weight: 400;
      }

      h4,
      h4 a {
        font-size: 1.25rem;
        font-weight: 500;
        color: $theme-white;
        margin-top: 2rem;
      }

      ul {
        padding-bottom: 1.5rem;
      }
    }

    .offcanvas-title {
      padding: 0;
      margin-right: 1.25rem;
      font-size: 1.1rem;
      margin-bottom: 2rem;
    }

    .offcanvas-title.active {
      border-bottom: $theme-black 2px solid;
    }
    //} //media break

    .offcanvas-header {
      padding-top: 20px;
      padding: 0;

      .btn-close {
        margin-bottom: 1rem !important;
        margin-top: 0 !important;
        margin-right: 1rem !important;
        padding: 0 !important;
      }

      .offcanvas-brand {
        max-width: 50px;
        margin-right: auto;

        .ar-logo {
          width: auto;
          fill: $theme-black;
          height: 45px;
          margin-bottom: 1rem;
          filter: brightness(0);
          .ar-logo-text {
            display: none;
          }
        }
      }
    }

    .offcanvas-footer {
      margin: 1rem;

      h3, p {
        font-size: 1rem !important;
        line-height: 1rem !important;
        padding: 0;
      }

    }

    @include media-breakpoint-up(md) {
      .offcanvas-footer {
 
        h3, p {
          font-size: 1.1rem !important;
          line-height: 1.1rem !important;

        }
  
      }
    }


  }
}
