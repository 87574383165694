// Archive
.woocommerce {
  span.onsale {
    background-color: $red;
    border-radius: 0;
    font-weight: 400;
    padding: 0;
    min-width: 6rem;
    min-height: 0;
    line-height: 2;
    margin-top: 1rem;
  }

  /*------------
* Woo stock statuses (global)
--------------*/

  .stock {
    background-color: $white !important;
    color: $gray-800 !important;
    font-size: 0.8em !important;
    line-height: 1;
    padding: 0.375rem 0.75rem;
    text-align: center;
    vertical-align: middle;
    margin: 0;
    height: fit-content;
    width: fit-content;
  }

  .card-body .stock {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .stock.in-stock {
    background-color: rgba(40, 167, 69, 0.25) !important;
  }

  .stock.available-on-backorder {
    background-color: rgba(209, 220, 37, 0.5) !important;
  }

  .stock.out-of-stock {
    background-color: rgba(168, 44, 44, 0.25) !important;
  }

  .stock.stock-order {
    background-color: rgba(44, 102, 168, 0.25) !important;
  }

  /*-------------
* Side-widget title, facetWP
---------------*/

  .side-widget .widget-title {
    font-size: 1rem;
    color: $gray-800;
    border-top: 1px solid $gray-300;
    padding-top: 1rem;
  }

  .archive-sidebar .textwidget {
    margin-top: 1rem;
  }

  /*--
* Cards
---*/

  .card {
    background-color: $white;
    filter: $shadow;

    h2.woocommerce-loop-product__title {
      font-weight: 400 !important;
      font-size: 1.25rem !important;
      line-height: 1.2 !important;
      padding: 0 !important;
    }
    img {
      margin-bottom: 1rem;
    }
    .card-footer {
      padding: 0 !important;
      border: none !important;
      background-color: inherit;

      span {
        color: $theme-black !important;
      }

    }
  }

  /*---
* Facet Styling
---*/

  .facetwp-facet {
    font-weight: 300;
    font-size: 1rem;
    line-height: 2rem;
    color: $black;
    font-family: Roboto, sans-serif;
    margin-bottom: 0 !important;

    .facetwp-toggle {
      font-size: 1rem;
    }

    .facetwp-counter {
      font-size: 0.8rem;
    }

    .facetwp-slider-wrap {
      padding-bottom: 0.5rem;
    }

    .facetwp-slider-label {
      text-align: center;
      display: block;
      padding-bottom: 1rem;
    }

    .facetwp-slider-reset {
      color: $gray-800;
      font-weight: 300;
      background-color: $gray-300;
      border-color: $gray-300;
      border: 1px solid transparent;
      padding: 0.375rem 0.75rem;
      line-height: 1.5;
      border-radius: 0.25rem;
      box-shadow: none;
    }
  }
  .facetwp-selections {
    
    ul {
      padding: 0;
      margin: 0;
    }

    li {
      padding: 0.5rem;
      border: 1px solid $gray-800;
      border-radius: 0.25em;
      font-weight: 500;
      font-size: 1rem;
    }
    .facetwp-selection-value {
      margin: 0.25em;
    }
    .facetwp-selection-label {
      display: none;
    }
  }

  .woocommerce-ordering {
    margin: 0 !important;
    float: none !important;
  }

  .facetwp-facet-pager_pages {
    text-align: center;
    margin: 2em !important;
  }

  .woocommerce-ordering,
  .facetwp-type-pager,
  .facetwp-type-search {
    a,
    select,
    input {
      font-size: 0.9em;
      font-weight: 400;
      line-height: 1.5;
      color: $gray-800;
    }
  }

  .facetwp-type-search {
    input {
      padding-top: .75rem;
      padding-bottom: .75rem;
    }
    .facetwp-search::placeholder {
      font-weight: 300;
    }
    .facetwp-icon {
      padding: .5rem;
    }
  }


  .woocommerce-result-count {
    font-size: 0.9em;
    line-height: 0.9em;
    margin: 0 !important;
    float: none !important;
    //height: 30px;
  }

  #routes-facet-search {
    margin-top: 3rem;
    padding: 1rem;
    border-radius: 0.25rem;
    border: 1px solid $gray-400;
    background: $gray-100
  }


  .archive-sidebar {
    margin-top: 7rem !important;

    .side-widget {
      br {
        display: none;
      }
      p {
        font-weight: 500;
        font-size: 0.9em;
        margin-bottom: 0;
        margin-top: 1em;
      }
      p:empty {
        display: none;
      }
    }
  }

  #collapseFilters {
    display: none;
  }

  .show {
    display: block !important;
  }
} //WooCommerce
