// Footer

#wrapper-footer-full {
  background: $theme-dark;
  padding: 3rem;

  .footer-widget {
    flex-basis: 0!important;
    -webkit-box-flex: 1!important;
    flex-grow: 1!important;
    max-width: 100%!important;
  }

  .ar-logo {
    fill: $theme-white;
    max-width: 350px;
  }
  
  .whistler-logo {
    max-width: 200px;
    margin-top: 1rem;
  }
  .widget-title {
    margin-top: 2rem;
  }

  h5, a {
    color: $theme-white !important;
    text-decoration: none !important;
  }
  ul {
    padding: 0;
    margin-top: 1rem;
  }
  li {
    font-size: 1rem;
    line-height: 2rem;
    a {
      white-space: nowrap;
    }
  }
}
