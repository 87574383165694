// Theme Frameworks
@import "assets/bootstrap5/bootstrap";         // <------- Loads Bootstrap5
@import "assets/understrap/theme/understrap";  // <------- Loads the Understrap defaults. Just a few classes to incorporate BS in WP
@import "assets/understrap/theme/woocommerce"; // <------- Loads WooCommerce style fixes. Comment out if you aren't using WooCommerce

// Optional files
//@import "assets/underscores";                  // <------- Underscores media styles
//@import "assets/understrap/theme/colors";      // <-------- This creates the necessary bootstrap color classes.
//@import "assets/understrap/theme/blocks";      

// Child theme general styles
@import "theme/child_theme_variables";          // <------- Add your variables into this file. Also add variables to overwrite Bootstrap variables here
@import "theme/child_theme";

// Pages
@import "theme/routes_frontpage";
@import "theme/routes_archives";

// Nav Areas
@import "theme/routes_nav";
@import "theme/routes_footer";  

// Woo
@import "theme/routes_woo_single_product";
@import "theme/routes_woo_archive";
@import "theme/routes_woo_spu";

// Plugin
@import "theme/routes_brands";
@import "theme/routes_reserve";
@import "theme/routes_snippets";
@import "theme/routes_rideguide";
@import "theme/routes_social_reviews";


// Fonts
//@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600&family=IBM+Plex+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,500;1,600;1,700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');