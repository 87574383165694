// Reserve_category archive
#reserve-archive-wrapper {
  background-color: $theme-dark;

  // media above 1500px
  @include media-breakpoint-up(xxl) {
    .reserve-archive-section {
      min-height: 65vh !important;
    }
  }

  @include media-breakpoint-down(lg) {
    #reserve-archive-intro {
      .reserve-archive-text {
        margin-top: -10rem !important;
      }

      .reserve-archive-title-wrapper {
        padding-left: 0 !important;
        padding-bottom: 2rem !important;
      }

      .reserve-archive-gallery {
        margin-top: 0 !important;
      }
    }
  }

  @include media-breakpoint-down(md) {
    #reserve-archive-content {
      .reserve-card-wrapper {
        width: auto !important;
      }
    }
  }

  // Global

  h2 {
    //  padding-top: 0;
    padding-bottom: 0;
  }

  // Feature Image
  .hero-row .card {
    padding: 0;
    max-height: 60vh;
    overflow: hidden;
    border: none !important;
    border-radius: 0 !important;

    img {
      width: 100%;
    }
  }

  #reserve-archive-content {
    @include media-breakpoint-down(md) {
      padding: 1rem;
    }

    color: $theme-white;
    padding-bottom: 6rem;

    h2,
    a {
      color: $theme-white;
    }

    .reserve-card-wrapper {
      width: 400px;
      height: 400px;
      background-color: $theme-secondary;

      .category-tag {
        writing-mode: vertical-rl;
        text-orientation: mixed;
        position: absolute;
        right: 2px;
        top: 8px;
        color: $theme-white;

        li {
          font-weight: 600;
          font-size: 1rem;
          line-height: normal;
          opacity: 0.75;
        }
      }
    }

    .reserve-card {
      border-radius: 0;
      border: none;

      height: 100%;
      margin: 6px;
      margin-right: 30px;
      display: block !important;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 60% 50%;
      }
      .card-img-overlay {
        background: rgb(0, 0, 0);
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.75) 0%,
          rgba(0, 0, 0, 0) 40%
        );
        border-radius: 0;

        .reserve_features {
          p {
            font-size: 0.8rem;
            line-height: normal;
            font-weight: 600;
            margin: 0;
          }
        }

        .entry-title {
          font-size: 2.25rem;
          color: $theme-white;
        }
      }
    }
  }

  #reserve-archive-intro {
    @include media-breakpoint-down(md) {
      padding: 1rem;
    }

    padding: 3rem;
    min-height: 50vh;

    .reserve-archive-text,
    .reserve-archive-gallery {
      position: relative;
      margin-top: -12rem;
    }

    .reserve-archive-text {
      margin-bottom: 4rem;
    }

    .reserve-archive-title-wrapper {
      color: $theme-white;

      .reserve-breadcrumb,
      a,
      h1 {
        padding-top: 0 !important;
        color: $theme-white;
      }
    }

    .reserve-cat-image {
      position: relative;
      img {
        width: 100%;
      }
    }

    .reserve-cat-gallery {
      padding-left: 0.75rem;
      padding-right: 0.75rem;

      .reserve-cat-gallery-item {
        padding: 0;
      }
    }
  }
} // #reserve-archive-wrapper

// Single product type template
#single-reserve-wrapper {
  .reserve-header-content {
    padding: 3rem;
  }

  .reserve-breadcrumb {
    font-size: 0.92em;
  }
  .entry-title {
    padding: 0 !important;
    margin-bottom: 1.5rem;
  }

  .pricing-callout {
    font-size: 2rem;
    font-weight: 500;
    span {
      font-size: 1.1rem;
      font-weight: 300;
    }
  }

  #reserve-book {
    position: relative;
    z-index: 10;
    margin-top: 3rem;
    margin-bottom: -16rem;
  }

  .reserve-images {
    .wp-post-image {
      padding: 1rem 0.5rem;
    }
  }
  .reserve-gallery {
    list-style: none;
    display: inline-flex;
    overflow: clip;
    padding: 0;

    .reserve-gallery-item {
      padding: 0.5rem;
    }
  }
} // .single-reserve

#reserve-related {
  .card-deck {
    padding-top: 2rem;
  }
  .card {
    filter: drop-shadow(0 3px 3px #cdcdcd);

    .card-text {
      font-size: 1rem;
    }

    .card-footer {
      border-top: none;
      font-style: italic;
      a {
        color: $gray-800;
      }
    }
  }
}
