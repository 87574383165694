// Reserve_category archive
#feature-archive-wrapper,
#workshop-service-wrapper {
  background-color: $theme-dark;

  @include media-breakpoint-down(lg) {
    #introduction {
      .header {
        margin-top: -10rem !important;
      }
    }
  }

  .hero-image {
    max-height: 60vh;
    overflow: hidden;
    border: none;
    border-radius: 0;

    img {
      width: 100%;
    }
  }

  #introduction {
    padding: 3rem;
    min-height: 30vh;

    .introduction-text,
    .gallery {
      position: relative;
      margin-top: -12rem;
    }

    .title-wrapper {
      color: $theme-white;

      .reserve-breadcrumb,
      a,
      h1 {
        padding-top: 0 !important;
        color: $theme-white;
      }
    }

    .reserve-cat-image img,
    .routes-gallery-image img {
      width: 100%;
    }

    .reserve-cat-gallery,
    .routes-gallery {
      padding: 0 0.75rem;

      .reserve-cat-gallery-item,
      .routes-gallery-item {
        padding: 0;
      }
    }

    .woocommerce-breadcrumb::content {
      content: "";
    }
  }

  #content {
    color: $theme-white;
    padding-bottom: 6rem;

    h2,
    a {
      color: $theme-white;
    }

    .reserve-card-wrapper {
      width: 400px;
      height: 400px;
      background-color: $theme-secondary;

      .category-tag {
        writing-mode: vertical-rl;
        text-orientation: mixed;
        position: absolute;
        right: 2px;
        top: 8px;
        color: $theme-white;

        li {
          font-weight: 600;
          font-size: 1rem;
          opacity: 0.75;
        }
      }
    }

    .reserve-card {
      border-radius: 0;
      border: none;
      height: 100%;
      margin: 6px 30px 6px 6px;
      display: block !important;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 60% 50%;
      }

      .card-img-overlay {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.75) 0%,
          rgba(0, 0, 0, 0) 40%
        );
        border-radius: 0;

        .reserve_features p {
          font-size: 0.8rem;
          font-weight: 600;
          margin: 0;
        }

        .entry-title {
          font-size: 2.25rem;
          color: $theme-white;
        }
      }
    }
  }

  #serviceAccordion {
    .accordion-item {
      border-radius: 0;
      border: 1px solid $gray-900;

      .accordion-button {
        font-weight: 500;
        background: $gray-500;
      }

      .accordion-body {
        background: $gray-200;
        color: $gray-900;

        p {
          font-weight: 400;
          font-size: 1rem;
          line-height: 1rem;
        }

        li {
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.5rem;
        }

        h5 {
          font-weight: 500;
          font-size: 1rem;
          line-height: 1rem;
          font-family: sans-serif !important;
        }
      }
    }
  }

  #serviceSteps {
    // Variables (customize as needed)
    $bullet-size: 20px;

    h3 {
      font-family: sans-serif !important;
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 1rem;
    }


    #lineRow {
      .line {
        background-color: $blue-200;
        height: 8px;
      }
      margin-bottom: -25px;
    }

    #stepRow {
      padding-top: -25px;

      .steps {
        background-color: $blue-200;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 40px;
        height: 40px;
      }
    }
  }
}
