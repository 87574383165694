.fd-product-brand {
  .brand-image {
    padding: 1rem !important;
  }

  @include media-breakpoint-down(lg) {
    .brand-image {
      max-width: 180px !important;
    }
  }

  @include media-breakpoint-down(md) {
    .brand-image {
      max-width: 160px !important;
    }
  }
}
