#social-reviews {
 .card {
    border: none;
    background: $gray-100;

    .card-title {
        font-weight: 500;
        margin-top: 1.5rem !important;
        margin-bottom: 1rem !important;
    }

    blockquote {
        font-size: 1rem;
    }

    figcaption {
        text-align: right;
    }
 }   
}