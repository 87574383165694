// Fix billing field wrapper
.woocommerce-input-wrapper {
	width: 100%;
}

// Woocommerce product gallery slider width fix
figure.woocommerce-product-gallery__wrapper {
	max-width: inherit !important;
}

// Fix coupon code input width for cart, apply only to the cart as it will break the checkout field width
@media(min-width: 768px) {
	.woocommerce-cart #coupon_code.input-text {
		width: 110px !important;
	}
}

// Fix input fields for Stripe Checkout fields
#stripe-payment-data {
	#wc-stripe-cc-form {
		.form-row {
			display: flex;
			flex-direction: column;
			.wc-stripe-elements-field {
				max-height: 2rem;
			}
		}
	}
	.woocommerce-SavedPaymentMethods-saveNew {
		display: inherit;
	}
}

.woocommerce-notices-wrapper .woocommerce-message {
	@extend .alert;
	@extend .alert-success;
	padding: 1em 2em 1em 3.5em;

	a.button {
		@extend .btn;
		@extend .btn-success;
	}

	&.woocommerce-error {
		@extend .alert-danger;
		a.button {
			@extend .btn-danger;
		}
	}
}

.woocommerce-info {
	@extend .alert;
	@extend .alert-info;
	padding: 1em 2em 1em 3.5em;
	a.button {
		@extend .btn;
		@extend .btn-info;
	}
}

// Accessibility - update color for required class
.woocommerce form .form-row .required {
	color: $danger;
}


// Accessibility requirement for color contrast
.woocommerce div.product p.price,
.woocommerce div.product span.price,
.woocommerce div.product .stock,
.woocommerce ul.products li.product .price,
#add_payment_method .cart-collaterals .cart_totals .discount td,
.woocommerce-cart .cart-collaterals .cart_totals .discount td,
.woocommerce-checkout .cart-collaterals .cart_totals .discount td {
	color: $green;
}
