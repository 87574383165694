$spu_border: 1px solid lightgrey;

.spu-wrap {
  .variations-form {
    padding-bottom: 1.5rem;

    label {
      font-weight: 400 !important;
    }
    .reset_variations {
      float: right;
    }
  }

  .quantity {
    margin: 0 !important;
  }
  // imitate col-sm-4
  .quantity {
    flex: 0 0 auto;
    width: 33.33333333%;

    input {
      width: 90% !important;
      line-height: 2.25 !important;
    }
  }

  .woocommerce-variation-add-to-cart button,
  .cart button {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .spu-options-wrap div {
    margin-bottom: 2rem;
    padding: 1rem;
    border: $spu_border;
    border-radius: 0.25rem;
    // column alignment
    margin-left: 0.75rem;
    max-width: 95%;
    background-color: $white;
    filter: $shadow;

    h5 {
      border-bottom: $spu_border;
      padding-bottom: 1rem;
      padding-top: 0.5rem;
    }
    div:not(:first-of-type) {
      border-left: $spu_border;
    }
  }
}

.spu-availability {
  width: 100% !important;
  text-align: left !important;
 // border: $spu_border;
  border-radius: 0.25rem;
  padding: 0.5rem !important;
  margin-bottom: 1rem !important;
  margin-top: 0.5rem !important;
}

.product-summary {
  p.price,
  span.price {
    color: black !important;
    margin-bottom: 1rem;
    margin-top: 2rem;
    font-family: $font-family-sans-serif;

    del bdi {
      font-size: 1rem !important;
    }

    bdi {
      font-size: 2rem;
      font-weight: 500;
    }
  }

  .product_meta .sku_wrapper{
    font-size: 0.75rem;
    font-weight: 300;
    opacity: 0.75;
  }

}
