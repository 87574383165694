// Fonts
.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}

#page {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $title-font;
  }

  p,
  a,
  li {
    font-family: $small-font;
  }

  small {
    font-family: $small-font;
  }

  h1 {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-weight: 700;
    font-size: 3rem;
  }

  h2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-weight: 600;
    font-size: 2rem;
  }

  h3 {
    padding-top: 2rem;
    font-weight: 400;
    font-size: 1.5rem;
  }

  h4 {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 2rem;
  }

  h5,
  h5 a,
  .nav-links a {
    font-weight: 400;
    font-size: 1.25rem;
  }

  p,
  li {
    font-weight: 350;
    font-size: 1.1rem;
    line-height: 2.25rem;
  }

  p a {
    color: $theme-dark;
    text-decoration: underline !important;
  }

  a {
    color: $theme-black;
    text-decoration: none !important;
  }

  a:hover {
    text-decoration: underline !important;
  }

  .btn {
    &:hover {
      text-decoration: none !important;
    }
  }

  .btn-primary {
    background-color: $theme-dark;
    border-color: $theme-dark;
    color: $theme-white;
    &:hover {
      background-color: $theme-white;
      border-color: $theme-white;
      color: $theme-dark;
    }
  }

  // add hyperlink symbol after external links
  #routesContent a[target="_blank"]::after {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
    margin: 0 3px 0 5px;
  }

  .btn {
    font-weight: inherit;
    font-size: inherit;
    text-decoration: none;
  }

  .whistler-logo {
    float: right;
    width: auto;
    height: 20px;
  }

  // Feature (workshop) page.
  .feature-page {
    .hero-img-wrap {
      height: 500px;
      overflow: hidden;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 75%);
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 75%);
    }
  }

  // Flickity, product and blog archive cards

  .blog-card,
  .product-card {
    width: 300px !important;
    height: 300px !important;
    margin-right: 30px !important;
    overflow: hidden;
    img {
      object-fit: cover;
    }
  }

  .service-carousel {
    .flickity-viewport {
      height: 1400px !important;
    }
  }

  .service-card {
    padding: 2rem;
    margin: 2rem;
    border: 1px solid $theme-black;

    .card-img-top,
    .card-body {
      width: 400px !important;
    }
    //height: auto !important;
    p {
      line-height: 1.5rem !important;
      font-size: 1rem !important;
    }
  }

  .flickity-prev-next-button {
    width: 48px !important;
    height: 48px !important;
  }

  .product-card img {
    height: 200px;
  }

  .blog-card img {
    height: 180px;
  }

  // blogs and pages

  .entry-content {
    iframe {
      display: none;
    }

    img {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: auto;
      margin-right: auto;
      display: block;
      padding: 3rem;
    }

    .rounded-circle {
      padding: 0;
    }

    figcaption {
      color: $theme-dark;
      font-style: italic;
      font-weight: 300;
      padding-top: 0.5rem;
    }
  }

  .blog-content {
    p:first-child::first-letter {
      font-size: 50px;
      line-height: 60px;
      padding-top: 5px;
      padding-left: 2px;
    }
  }

  // archives

  .archive-header {
    margin-bottom: 4rem;
  }

  .archive-sidebar {
    //margin-top: 4rem;
    .textwidget {
      margin-top: 2rem;
    }
  }

  .pagination .page-item.active .page-link {
    background-color: $feature-light;
    border-color: $feature-light;
  }

  .pagination .page-item .page-link {
    color: $theme-black;
  }

  .woocommerce-breadcrumb,
  .reserve-breadcrumb {
    padding-top: 1em !important;
    color: $theme-dark;
    a {
      color: $theme-dark;
    }
  }

  //workshop
  .workshop-header {
    margin-top: -150px;
    @include media-breakpoint-up(sm) {
      margin-top: -50px !important;
    }
  }
}
